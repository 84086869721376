:root{
    --ion-color-customOrange: #ff6e16;
    --ion-color-customOrange-rgb: 255,110,22;
    --ion-color-customOrange-contrast: #000000;
    --ion-color-customOrange-contrast-rgb: 0,0,0;
    --ion-color-customOrange-shade: #e06113;
    --ion-color-customOrange-tint: #ff7d2d;

    .ion-color-customOrange {
        --ion-color-base: var(--ion-color-customOrange) !important;
        --ion-color-base-rgb: var(--ion-color-customOrange-rgb) !important;
        --ion-color-contrast: var(--ion-color-customOrange-contrast) !important;
        --ion-color-contrast-rgb: var(--ion-color-customOrange-contrast-rgb) !important;
        --ion-color-shade: var(--ion-color-customOrange-shade) !important;
        --ion-color-tint: var(--ion-color-customOrange-tint) !important;
    }
}