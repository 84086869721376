/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "mapbox-gl/dist/mapbox-gl.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/colors.scss";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

bar-rating {
  --br-gap: 5px !important;
  --br-font-size: 28px !important;
  --br-width: var(--br-font-size);
  --br-active-color: #f56e18;

  --br-height: var(--br-font-size);
  display: flex;
  justify-content: center;

  .br-unit-inner {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='iso-8859-1'?%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512.002 512.002' style='enable-background:new 0 0 512.002 512.002;' xml:space='preserve'%3E%3Cpath style='fill:%23F0D355;' d='M400.07,502.64c-3.551,0-7.116-0.825-10.398-2.5L256.001,431.95L122.33,500.14 c-3.283,1.675-6.848,2.5-10.399,2.5c-4.728,0-9.431-1.464-13.408-4.336c-6.961-5.031-10.52-13.537-9.215-22.026l22.995-149.648 L6.579,219.236c-6.002-6.096-8.103-15.031-5.446-23.163c2.656-8.131,9.626-14.103,18.069-15.481l147.912-24.138l68.49-134.585 c3.904-7.675,11.786-12.507,20.397-12.507c8.611,0,16.493,4.833,20.396,12.507l68.49,134.585L492.8,180.592 c8.444,1.378,15.413,7.35,18.07,15.481c2.656,8.132,0.555,17.067-5.447,23.163L399.7,326.628l22.993,149.648 c1.305,8.49-2.254,16.995-9.215,22.026C409.502,501.175,404.798,502.64,400.07,502.64z'/%3E%3Cg style='opacity:0.1;'%3E%3Cpath style='fill:%23414042;' d='M146.585,486.578c-6.96-5.03-10.52-13.537-9.215-22.026l22.995-149.647L54.643,207.512 c-6.003-6.096-8.103-15.031-5.447-23.163c1.232-3.769,3.397-7.065,6.199-9.663l-36.193,5.906 c-8.444,1.378-15.413,7.35-18.069,15.481c-2.657,8.132-0.556,17.067,5.446,23.163l105.724,107.391L89.307,476.276 c-1.305,8.49,2.254,16.995,9.215,22.026c3.977,2.872,8.68,4.336,13.408,4.336c3.551,0,7.117-0.826,10.399-2.5l25.267-12.89 C147.257,487.03,146.914,486.817,146.585,486.578z'/%3E%3C/g%3E%3C/svg%3E");
    width: var(--br-width);
    height: var(--br-height);
    filter: grayscale(1);

    &.br-active {
      filter: grayscale(0);
    }
  }
}

// @import "~swiper/scss";
// @import "~swiper/scss/autoplay";
// @import "~swiper/scss/keyboard";
// @import "~swiper/scss/pagination";
// @import "~swiper/scss/scrollbar";
// @import "~swiper/scss/zoom";
.authLogo {
  width: 20%;
  margin: auto;
  display: block;
  // margin-top: 15%;
}
.swiper-pagination {
  margin-bottom: 25px;
}
.globalStyle::part(content) {
  width: 90%;
  height: 80%;
  margin: auto;
  display: block;
  border-radius: 35px;
}
.addDirection::part(content) {
  height: 70%;
  width: 60%;
  margin: auto;
  display: block;
  border-radius: 35px;
}
.authStyle::part(content) {
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
}
.mapStyle::part(content) {
  width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  position: fixed;
  bottom: 0;
}
.paymentModal::part(content) {
  width: 100%;
  height: 40%;
  margin: auto;
  display: block;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  position: fixed;
  bottom: 0;
}
@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-Regular.ttf");
}
* {
  font-family: "Product Sans", sans-serif !important;
}
.detailsModal::part(content) {
  width: 50%;
  height: 80%;
  margin: auto;
  display: block;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  position: fixed;
  background: red;
  bottom: 0;
}
.editionModal::part(content) {
  width: 100%;
  height: 40%;
  margin: auto;
  display: block;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  position: fixed;
  bottom: 0;
}

.modalHorario::part(content) {
  opacity: 1;
  width: 90%;
  height: 60%;
  border-radius: 10px;
  overflow: hidden;
}
.atendde-modal::part(content) {
  width: 100%;
  height: 50%;
  margin: auto;
  display: block;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  position: fixed;
  bottom: 0;
}
.new-event::part(content) {
  opacity: 1;
  width: 33%;
  height: 95%;
  border-radius: 10px;
  overflow: hidden;
}
.waiting-modal::part(content) {
  opacity: 1;
  width: 90%;
  height: 35%;
  border-radius: 10px;
  overflow: hidden;
}
.repeatModalTime::part(content) {
  opacity: 1;
  width: 90%;
  height: 40%;
  border-radius: 10px;
  overflow: hidden;
}

ol.steps {
  padding: 0 !important;
}

div.stepper-body {
  margin: 0 !important;
  padding: 0 !important;
}

.center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-input {
  border: 1px solid #ff6e16;
  border-radius: 10px;
  ::placeholder {
    font-size: 14px;
    padding-left: 10px;
  }
  .textarea-wrapper {
    ::placeholder {
      font-size: 14px;
      padding-left: 10px;
    }
  }
  ::part(placeholder) {
    margin-left: 10px;
    font-size: 14px;
  }
}

ion-select.form-input {
  ::part(placeholder) {
    margin-left: 10px;
    font-size: 14px;
  }
  div.select-text.select-placeholder {
  }
}

ion-img::part(img) {
  border-radius: 10px;
}

.btn-border-orange {
  border: 1px solid var(--ion-color-primary);
  border-radius: 10px;
}

@media screen and (min-width: 500px) {
  .paymentModal::part(content) {
    width: 40%;
    height: 40%;
    margin: auto;
    display: block;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    position: fixed;
    bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  .new-event::part(content) {
    opacity: 1;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  .detailsModal::part(content) {
    width: 100%;
    height: 50%;
    margin: auto;
    display: block;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    position: fixed;
    background: red;
    bottom: 0;
  }
}

// swiper-container {
//   --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
//   --swiper-pagination-color: var(--ion-color-primary, #3880ff);
//   --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
//   --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
//   --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
// }

// swiper-slide {
//   display: flex;
//   position: relative;

//   flex-direction: column;
//   flex-shrink: 0;
//   align-items: center;
//   justify-content: center;

//   width: 100%;
//   height: 100%;

//   font-size: 18px;

//   text-align: center;
//   box-sizing: border-box;
// }

// swiper-slide img {
//   width: auto;
//   max-width: 100%;
//   height: auto;
//   max-height: 100%;
// }
